import { useLabel } from "@/core/context/LabelsContext"
import FeedEmptyStateIcon from "@/core/ui/images/empty-state-illustrations/feed-emptystate.svg"
import { DiscoEmptyState } from "@disco-ui"

type Props = {
  canCreatePosts?: boolean
}

function ProductFeedPostsEmptyState({ canCreatePosts }: Props) {
  const experienceLabel = useLabel("experience")

  return (
    <DiscoEmptyState
      testid={"ProductFeedPostsPage.empty-state"}
      icon={<FeedEmptyStateIcon />}
      title={canCreatePosts ? "You have no Posts" : "There are no Posts yet"}
      subtitle={
        canCreatePosts
          ? `Posts are a way of communicating with your entire Cohort and drive them back to the ${experienceLabel.singular} to create engagement. For example, you can remind them of an upcoming event.`
          : "This feed is currently empty. Please check back later!"
      }
    />
  )
}

export default ProductFeedPostsEmptyState
