/**
 * @generated SignedSource<<044d43d74086aadd7c33ac4a153bd10f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductAppVisibility = "admin" | "all" | "membership" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AIFeedEmptyStateFragment$data = {
  readonly app: {
    readonly customAppDescription: string | null;
    readonly id: string;
    readonly visibility: ProductAppVisibility;
    readonly " $fragmentSpreads": FragmentRefs<"FeedAppSettingsButtonFragment">;
  };
  readonly id: string;
  readonly product: {
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  } | null;
  readonly productId: string | null;
  readonly " $fragmentType": "AIFeedEmptyStateFragment";
};
export type AIFeedEmptyStateFragment$key = {
  readonly " $data"?: AIFeedEmptyStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AIFeedEmptyStateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AIFeedEmptyStateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visibility",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customAppDescription",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedAppSettingsButtonFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "usePermissionsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Feed",
  "abstractKey": null
};
})();

(node as any).hash = "532060580ccb20211ff04d70813df1f5";

export default node;
