import FeedAppSettingsButton from "@/apps/list/app/feed/settings-button/FeedAppSettingsButton"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FeedEmptyStateIcon from "@/core/ui/images/empty-state-illustrations/feed-emptystate.svg"
import CreatePostWithAIButton from "@/post/list/empty-state/CreatePostWithAIButton"
import { AIFeedEmptyStateFragment$key } from "@/post/list/empty-state/__generated__/AIFeedEmptyStateFragment.graphql"
import { DiscoButton, DiscoEmptyState } from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import { graphql, useFragment } from "react-relay"

interface Props {
  feedKey: AIFeedEmptyStateFragment$key
}

function AIFeedEmptyState({ feedKey }: Props) {
  const activeOrganization = useActiveOrganization()

  const feed = useFragment<AIFeedEmptyStateFragment$key>(
    graphql`
      fragment AIFeedEmptyStateFragment on Feed {
        id
        productId
        app {
          id
          visibility
          customAppDescription
          ...FeedAppSettingsButtonFragment
        }
        product {
          ...usePermissionsFragment
        }
      }
    `,
    feedKey
  )
  const productPermissionsCheck = usePermissions(feed.product)
  const permissions = feed.productId
    ? productPermissionsCheck
    : activeOrganization?.viewerPermissions

  if (!feed.app.customAppDescription && permissions?.has("apps.manage")) {
    return (
      <DiscoEmptyState
        testid={"AIFeedEmptyState.add-description"}
        icon={<FeedEmptyStateIcon />}
        title={`Add a Feed Description for customized Disco AI suggestions.`}
        subtitle={`Receive post suggestions to engage your community.`}
        buttons={
          <FeedAppSettingsButton appKey={feed.app}>
            {(buttonProps) => (
              <DiscoButton {...buttonProps} leftIcon={"add"}>
                {"Feed Description"}
              </DiscoButton>
            )}
          </FeedAppSettingsButton>
        }
      />
    )
  }

  return (
    <DiscoEmptyState
      icon={<FeedEmptyStateIcon />}
      testid={"AIFeedEmptyState.empty-state.create-post-with-ai"}
      title={`No posts yet`}
      subtitle={`Add your first post to start engaging your community!`}
      buttons={
        feed.app.customAppDescription && <CreatePostWithAIButton feedId={feed.id} />
      }
    />
  )
}

export default AIFeedEmptyState
